// src/services/api.js
import axios from 'axios';

const BASE_URL = '/api';

const getOrCreateDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = `device_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
    localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
};

// Create axios instance
const api = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    const deviceId = getOrCreateDeviceId();
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['X-Device-ID'] = deviceId;
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const authService = {
  login: async (credentials) => {
    try {
      const deviceId = getOrCreateDeviceId();
      
      const response = await fetch(`${BASE_URL}/login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Device-ID': deviceId
        },
        body: JSON.stringify({
          ...credentials,
          deviceId
        })
      });

      const data = await response.json();

      if (data.status === 'success' && data.data?.token) {
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('user', JSON.stringify(data.data.user));
        return data;
      }

      throw new Error(data.message || 'Login failed');
    } catch (error) {
      throw error;
    }
  },

  logout: async () => {
    try {
      const token = localStorage.getItem('token');
      const deviceId = getOrCreateDeviceId();

      await fetch(`${BASE_URL}/logout.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Device-ID': deviceId
        }
      });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.clear();
      window.location.href = '/login';
    }
  },

  validateToken: async () => {
    try {
      const token = localStorage.getItem('token');
      const deviceId = getOrCreateDeviceId();
      
      if (!token || !deviceId) return false;

      const response = await fetch(`${BASE_URL}/validate-token.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Device-ID': deviceId
        }
      });

      const data = await response.json();
      return data.status === 'success';
    } catch (error) {
      return false;
    }
  }
};

export const channelService = {
  getChannels: async () => {
    try {
      const response = await api.get('/index.php/channels');
      if (response.status === 'success' && Array.isArray(response.data)) {
        // Group channels by category
        const grouped = response.data.reduce((acc, channel) => {
          const category = channel.category || 'Khác';
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            ...channel,
            logo_url: channel.logo_url || '/placeholder.png'
          });
          return acc;
        }, {});
        return grouped;
      }
      throw new Error(response.message || 'Failed to fetch channels');
    } catch (error) {
      console.error('Channel fetch error:', error);
      throw error;
    }
  },

  getEPG: async (epgUrl) => {
    try {
      if (!epgUrl) return null;

      // Create AbortController for timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000);

      // Log the EPG request
      console.log('Fetching EPG from proxy:', epgUrl);

      const token = localStorage.getItem('token');
      const deviceId = getOrCreateDeviceId();

      // Use the proxy endpoint
      const response = await fetch(`${BASE_URL}/epg.php?url=${encodeURIComponent(epgUrl)}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Device-ID': deviceId,
          'Accept': 'text/xml'
        },
        signal: controller.signal
      });

      // Clear timeout
      clearTimeout(timeoutId);

      // Log response status
      console.log('EPG proxy response status:', response.status);

      if (!response.ok) {
        throw new Error(`EPG fetch failed with status: ${response.status}`);
      }

      // Get response as text
      const xmlText = await response.text();
      
      // Log response length for debugging
      console.log('EPG response length:', xmlText.length);
      
      // Validate XML content
      if (!xmlText || !xmlText.includes('<?xml')) {
        console.error('Invalid XML response:', xmlText.substring(0, 200));
        throw new Error('Invalid XML response');
      }

      return xmlText;

    } catch (error) {
      console.error('EPG fetch error:', error);
      // Return null instead of throwing to avoid breaking the UI
      return null;
    }
  },

  getPackage: async (packageId) => {
    try {
      const response = await api.get(`/index.php/packages/${packageId}`);
      if (response.status === 'success') {
        return response.data;
      }
      throw new Error(response.message || 'Failed to fetch package');
    } catch (error) {
      console.error('Package fetch error:', error);
      throw error;
    }
  },

  validateStream: async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      return false;
    }
  }
};

export default api;