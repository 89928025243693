// src/components/ChannelCard.jsx
import React from 'react';
import { COLORS, SHADOWS } from '../constants/theme';

const ChannelCard = ({ channel, isActive, onClick }) => {
  return (
    <div
      onClick={() => onClick(channel)}
      className={`
        relative group
        aspect-square bg-white rounded-xl cursor-pointer
        transition-all duration-300 ease-in-out
        hover:shadow-xl hover:scale-105
        ${isActive 
          ? 'ring-2 ring-primary-500 shadow-lg transform scale-105' 
          : 'border border-gray-200 hover:border-primary-200'}
      `}
    >
      {/* Channel Logo */}
      <div className="absolute inset-0 p-4">
        <img
          src={channel.logo_url}
          alt={channel.name}
          className="w-full h-full object-contain transition-transform duration-300 group-hover:scale-110"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/placeholder.png';
          }}
        />
      </div>

      {/* Hover Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-gray-900/50 via-transparent to-transparent 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl">
        <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
          <p className="text-sm font-medium truncate">{channel.name}</p>
          {channel.category && (
            <p className="text-xs text-gray-200 truncate">{channel.category}</p>
          )}
        </div>
      </div>

      {/* Active Indicator */}
      {isActive && (
        <div className="absolute top-2 right-2 w-3 h-3 bg-primary-500 rounded-full 
                      animate-pulse shadow-lg shadow-primary-500/50" />
      )}
    </div>
  );
};

export default ChannelCard;
