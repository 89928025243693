// src/pages/Radio.jsx
import React, { useEffect } from 'react';

const Radio = () => {
  useEffect(() => {
    const originalStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <div className="fixed inset-0 w-full h-full bg-white">
      <iframe
        src="https://www.spacesport.xyz/tabs/radio"
        className="w-full h-full border-none"
        title="Radio"
        sandbox="allow-same-origin allow-scripts allow-forms"
      />
    </div>
  );
};

export default Radio;