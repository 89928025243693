// src/components/CurrentProgramInfo.jsx
import React from 'react';
import { COLORS } from '../constants/theme';

const CurrentProgramInfo = ({ channel, currentProgram }) => {
  if (!channel) return null;

  return (
    <div className="p-6 space-y-4">
      <div className="space-y-2">
        <h2 className="text-2xl font-bold text-gray-800 flex items-center">
          {channel.name}
          <span className="ml-3 px-2.5 py-0.5 text-xs font-medium rounded-full 
                         bg-success-50 text-success-600">
            Trực tiếp
          </span>
        </h2>
        {channel.category && (
          <p className="text-gray-600 flex items-center">
            <svg className="w-4 h-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
            </svg>
            {channel.category}
          </p>
        )}
      </div>

      {currentProgram && (
        <div className="bg-gradient-to-r from-primary-50 to-primary-50/50 
                      rounded-xl border border-primary-100 p-4">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center">
                <svg className="w-6 h-6 text-primary-600" fill="none" viewBox="0 0 24 24" 
                     stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
              </div>
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-primary-700">
                Chương trình đang phát
              </p>
              <h3 className="mt-1 text-lg font-semibold text-gray-900 leading-6">
                {currentProgram.program}
              </h3>
              <p className="mt-1 text-sm text-primary-600">
                Bắt đầu lúc: {currentProgram.time}
              </p>
            </div>
            <div className="flex-shrink-0">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full 
                           text-xs font-medium bg-primary-100 text-primary-800">
                <span className="w-2 h-2 mr-1.5 bg-primary-500 rounded-full animate-pulse" />
                Live
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentProgramInfo;
