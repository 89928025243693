// src/components/DateSelector.jsx
import React from 'react';
import { COLORS } from '../constants/theme';

const DateSelector = ({ dates, selectedDate, onDateSelect }) => {
  return (
    <div className="p-4 border-b border-gray-100">
      <div className="flex space-x-2 overflow-x-auto pb-2 scrollbar-thin 
                    scrollbar-thumb-gray-300 scrollbar-track-transparent">
        {dates.map(date => {
          const isSelected = selectedDate === date;
          const isToday = date === new Date().toISOString().slice(0, 10);
          
          return (
            <button
              key={date}
              onClick={() => onDateSelect(date)}
              className={`
                px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap
                transition-all duration-300 ease-in-out
                ${isSelected 
                  ? 'bg-primary-500 text-white shadow-md shadow-primary-500/20' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}
                ${isToday && !isSelected ? 'ring-2 ring-primary-500/30' : ''}
              `}
            >
              {new Date(date).toLocaleDateString('vi-VN', {
                weekday: 'short',
                month: 'numeric',
                day: 'numeric'
              })}
              {isToday && (
                <span className="ml-1.5 text-xs font-normal">
                  (Hôm nay)
                </span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DateSelector;
