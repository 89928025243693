import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: true
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showForceLogin, setShowForceLogin] = useState(false);

  useEffect(() => {
    // Kiểm tra thông báo tài khoản hết hạn từ reset password
    const expiredMessage = localStorage.getItem('accountExpiredMessage');
    if (expiredMessage) {
      setError(expiredMessage);
      localStorage.removeItem('accountExpiredMessage');
    }

    // Tải thông tin đăng nhập đã lưu nếu có
    const lastUser = localStorage.getItem('lastUser');
    if (lastUser) {
      try {
        const userData = JSON.parse(lastUser);
        setFormData(prevState => ({
          ...prevState,
          username: userData.username || '',
          password: userData.password || '',
          rememberMe: true
        }));
      } catch (error) {
        console.error('Error loading last user:', error);
        localStorage.removeItem('lastUser');
      }
    }

    // Khởi tạo deviceId nếu chưa có
    if (!localStorage.getItem('deviceId')) {
      const generatedDeviceId = generateDeviceId();
      localStorage.setItem('deviceId', generatedDeviceId);
    }
  }, []);

  // Hàm tạo device ID duy nhất và ổn định
  const generateDeviceId = () => {
    const browserInfo = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      colorDepth: window.screen.colorDepth
    };
    
    const str = JSON.stringify(browserInfo);
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return `device_${Math.abs(hash).toString(36)}_${navigator.platform.replace(/\s+/g, '')}`;
  };

  const handleSubmit = async (e, forceLogin = false) => {
    e?.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      let deviceId = localStorage.getItem('deviceId');
      if (!deviceId) {
        deviceId = generateDeviceId();
        localStorage.setItem('deviceId', deviceId);
      }
      
      const response = await fetch('/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Device-ID': deviceId
        },
        body: JSON.stringify({
          username: formData.username,
          password: formData.password,
          deviceId,
          forceLogin,
          rememberMe: formData.rememberMe ? 1 : 0
        })
      });

      const data = await response.json();

      if (data.status === 'success' && data.data?.token) {
        // Lưu thông tin đăng nhập nếu chọn ghi nhớ
        if (formData.rememberMe) {
          localStorage.setItem('lastUser', JSON.stringify({
            username: formData.username,
            password: formData.password
          }));
        } else {
          localStorage.removeItem('lastUser');
        }

        // Lưu thông tin xác thực
        localStorage.setItem('deviceId', deviceId);
        
        // Sử dụng AuthContext login
        login(data.data.user, data.data.token);

        if (formData.rememberMe) {
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('rememberMe');
          const expiry = new Date().getTime() + (24 * 60 * 60 * 1000);
          localStorage.setItem('tokenExpiry', expiry.toString());
        }

        // Chuyển hướng về trang chủ
        navigate('/');
      } else if (data.message?.includes('đang được đăng nhập')) {
        setShowForceLogin(true);
        setError(data.message);
      } else {
        setError(data.message || 'Đăng nhập thất bại');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Có lỗi xảy ra khi đăng nhập. Vui lòng thử lại.');
    } finally {
      setIsLoading(false);
    }
  };

  const clearLastUser = () => {
    localStorage.removeItem('lastUser');
    setFormData({
      username: '',
      password: '',
      rememberMe: true
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Đăng nhập NEWTV
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Chào mừng bạn đến với dịch vụ NEWTV của chúng tôi
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="text-sm text-red-700">{error}</div>
              {showForceLogin && (
                <button
                  type="button"
                  onClick={(e) => handleSubmit(e, true)}
                  className="mt-2 text-sm font-medium text-red-600 hover:text-red-500"
                >
                  Đăng xuất thiết bị cũ và đăng nhập
                </button>
              )}
            </div>
          )}

          <form className="space-y-6" onSubmit={(e) => handleSubmit(e, false)}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                Tên đăng nhập
              </label>
              <div className="mt-1 relative">
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.username}
                  onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                  disabled={isLoading}
                />
                {formData.username && (
                  <button
                    type="button"
                    onClick={clearLastUser}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    title="Xóa thông tin đăng nhập"
                  >
                    <svg className="h-5 w-5 text-gray-400 hover:text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Mật khẩu
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={formData.rememberMe}
                  onChange={(e) => setFormData({ ...formData, rememberMe: e.target.checked })}
                  disabled={isLoading}
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Ghi nhớ đăng nhập
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Quên mật khẩu?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    Đang đăng nhập...
                  </span>
                ) : 'Đăng nhập'}
              </button>
            </div>

            {/* Phần đăng ký mới */}
            <div className="mt-6 flex flex-col space-y-4">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Hoặc</span>
                </div>
              </div>
              
              <div className="text-center">
                <Link
                  to="/register"
                  className="w-full inline-flex justify-center py-2 px-4 border border-blue-600 rounded-md shadow-sm text-sm font-medium text-blue-600 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Đăng ký tài khoản mới
                </Link>
              </div>
              
              <p className="text-xs text-center text-gray-500">
                Khi đăng ký, bạn sẽ được dùng thử gói FREE trong 7 ngày.
                <br />
                Để nâng cấp lên gói cao cấp, vui lòng liên hệ help.newtv@gmail.com
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;