import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayer = ({ channel }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const fadeIntervalRef = useRef(null);
  const [isUsingBackup, setIsUsingBackup] = useState(false);
  const reconnectAttemptRef = useRef(null);
  const healthCheckIntervalRef = useRef(null);
  const primaryRetryTimeoutRef = useRef(null);
  const errorCountRef = useRef(0);
  const isHandlingErrorRef = useRef(false);
  const currentChannelRef = useRef(null);
  const stallTimeoutRef = useRef(null);
  const tokenRef = useRef(null);
  const tokenRefreshIntervalRef = useRef(null);

  const getNewToken = async () => {
    try {
      const response = await fetch('/api/token.php');
      const data = await response.json();
      if (data.status === 'success' && data.data?.token) {
        tokenRef.current = data.data.token;
        return data.data.token;
      }
      throw new Error('Invalid token response');
    } catch (error) {
      console.error('Token fetch error:', error);
      throw error;
    }
  };

  const getStreamUrl = (token) => {
    if (!token || !channel?.id) return null;
    return 'https://' + window.location.hostname + '/hls/' + token + '/' + channel.id + '.m3u8';
  };

  const startTokenRefresh = () => {
    if (tokenRefreshIntervalRef.current) {
      clearInterval(tokenRefreshIntervalRef.current);
    }

    tokenRefreshIntervalRef.current = setInterval(async () => {
      try {
        const newToken = await getNewToken();
        const player = playerRef.current;
        
        if (player && currentChannelRef.current?.id === channel?.id) {
          const currentTime = player.currentTime();
          const streamUrl = getStreamUrl(newToken);
          
          player.src({
            src: streamUrl,
            type: 'application/x-mpegURL'
          });

          player.one('loadeddata', () => {
            if (currentTime > 0) {
              player.currentTime(currentTime);
            }
            player.play();
          });
        }
      } catch (error) {
        console.error('Token refresh error:', error);
      }
    }, 3.5 * 60 * 60 * 1000);
  };

  const fadeInVolume = (player, startVolume = 0.7, endVolume = 0.9, duration = 2000, steps = 20) => {
    if (fadeIntervalRef.current) {
      clearInterval(fadeIntervalRef.current);
    }

    player.volume(startVolume);
    
    const volumeRange = endVolume - startVolume;
    const stepDuration = duration / steps;
    let currentStep = 0;

    fadeIntervalRef.current = setInterval(() => {
      currentStep++;
      const progress = currentStep / steps;
      const easeInOutProgress = progress < 0.5
        ? 2 * progress * progress
        : 1 - Math.pow(-2 * progress + 2, 2) / 2;
      
      const newVolume = startVolume + (volumeRange * easeInOutProgress);
      player.volume(Math.min(newVolume, endVolume));

      if (currentStep >= steps) {
        clearInterval(fadeIntervalRef.current);
        fadeIntervalRef.current = null;
        player.volume(endVolume);
      }
    }, stepDuration);
  };

  const startStreamHealthCheck = (player) => {
    if (healthCheckIntervalRef.current) {
      clearInterval(healthCheckIntervalRef.current);
    }

    healthCheckIntervalRef.current = setInterval(() => {
      if (currentChannelRef.current?.id !== channel?.id) {
        clearInterval(healthCheckIntervalRef.current);
        return;
      }

      if (!player.paused() && !player.ended() && !isHandlingErrorRef.current) {
        const isPlayerStuck = (
          player.readyState() === 0 || 
          player.networkState() === 3 ||
          (player.buffered().length === 0 && Date.now() - player.lastPlayTime > 15000)
        );
                           
        if (isPlayerStuck || player.error()) {
          handleStreamFailure(player);
        }
      }
    }, 10000);
  };

  const startPrimaryStreamCheck = (player) => {
    if (reconnectAttemptRef.current) {
      clearInterval(reconnectAttemptRef.current);
    }

    reconnectAttemptRef.current = setInterval(async () => {
      try {
        if (currentChannelRef.current?.id !== channel?.id) {
          clearInterval(reconnectAttemptRef.current);
          return;
        }

        console.log('Checking primary stream availability...');
        
        if (!tokenRef.current) {
          tokenRef.current = await getNewToken();
        }

        const streamUrl = getStreamUrl(tokenRef.current);
        const response = await fetch(streamUrl);
        
        if (response.ok) {
          console.log('Primary stream is available, switching back...');
          
          try {
            const currentTime = player.currentTime();
            await switchToPrimaryStream(player, currentTime);
            console.log('Successfully switched back to primary stream');
            
            clearInterval(reconnectAttemptRef.current);
            reconnectAttemptRef.current = null;
          } catch (error) {
            console.error('Failed to switch back to primary:', error);
          }
        }
      } catch (error) {
        console.log('Primary stream still not available');
      }
    }, 30000);
  };

  const handleStreamFailure = async (player, error = null) => {
    if (currentChannelRef.current?.id !== channel?.id || isHandlingErrorRef.current) {
      return;
    }

    isHandlingErrorRef.current = true;
    const currentTime = player.currentTime();
    errorCountRef.current += 1;

    try {
      console.log('Stream failure detected:', {
        channelId: channel?.id,
        isUsingBackup,
        errorCount: errorCountRef.current,
        error,
        currentTime
      });

      if (!isUsingBackup) {
        try {
          console.log('Attempting to recover primary stream...');
          
          if (primaryRetryTimeoutRef.current) {
            clearTimeout(primaryRetryTimeoutRef.current);
          }

          await new Promise((resolve, reject) => {
            primaryRetryTimeoutRef.current = setTimeout(async () => {
              try {
                if (currentChannelRef.current?.id === channel?.id) {
                  await switchToPrimaryStream(player, currentTime);
                  console.log('Successfully recovered primary stream');
                  resolve();
                } else {
                  reject(new Error('Channel changed during recovery'));
                }
              } catch (error) {
                reject(error);
              }
            }, 10000);
          });
        } catch (recoveryError) {
          console.log('Primary stream recovery failed:', recoveryError);
          
          if (channel?.backup_stream_url && currentChannelRef.current?.id === channel?.id) {
            try {
              console.log('Switching to backup stream...');
              await switchToBackupStream(player, currentTime);
              console.log('Successfully switched to backup stream');
              startPrimaryStreamCheck(player);
            } catch (backupError) {
              console.error('Failed to switch to backup:', backupError);
              if (currentChannelRef.current?.id === channel?.id) {
                displayError(player, 'Không thể kết nối tới server. Vui lòng thử lại sau.');
              }
            }
          }
        }
      } else {
        startPrimaryStreamCheck(player);
      }
    } finally {
      isHandlingErrorRef.current = false;
    }
  };

  const displayError = (player, message) => {
    if (currentChannelRef.current?.id === channel?.id) {
      player.error({
        code: 4,
        message: message
      });
    }
  };

  const switchToStream = async (usePrimary, player, currentTime) => {
    try {
      player.error(null);

      if (!tokenRef.current) {
        tokenRef.current = await getNewToken();
      }

      const streamUrl = getStreamUrl(tokenRef.current);
      if (!streamUrl) {
        throw new Error('Could not generate stream URL');
      }
      
      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL'
      });

      await new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error('Stream loading timeout'));
        }, 15000);

        const handleError = (error) => {
          clearTimeout(timeout);
          reject(error);
        };

        const handleLoadeddata = () => {
          clearTimeout(timeout);
          resolve();
        };

        player.one('loadeddata', handleLoadeddata);
        player.one('error', handleError);

        player.play().catch(error => {
          if (error.name === 'NotAllowedError') {
            handleLoadeddata();
          } else {
            handleError(error);
          }
        });

        return () => {
          clearTimeout(timeout);
          player.off('loadeddata', handleLoadeddata);
          player.off('error', handleError);
        };
      });

      if (currentChannelRef.current?.id === channel?.id) {
        if (currentTime > 0) {
          player.currentTime(currentTime);
        }
        
        errorCountRef.current = 0;
        player.lastPlayTime = Date.now();
        startStreamHealthCheck(player);
      }
      
      return true;
    } catch (error) {
      console.error('Stream switch failed:', error);
      throw error;
    }
  };

  const switchToPrimaryStream = async (player, currentTime) => {
    await switchToStream(true, player, currentTime);
    setIsUsingBackup(false);
  };

  const switchToBackupStream = async (player, currentTime) => {
    if (!channel?.backup_stream_url) {
      throw new Error('No backup stream available');
    }
    await switchToStream(false, player, currentTime);
    setIsUsingBackup(true);
  };

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      
      // Add custom styles for player UI
      const style = document.createElement('style');
      style.textContent = `
        .video-js .vjs-control-bar {
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          padding: 0 1rem;
        }

        .video-js .vjs-volume-panel {
          margin-right: 1rem;
        }

        .video-js .vjs-volume-control {
          width: 8em;
        }

        .video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
          height: 8em;
        }

        .video-js .vjs-volume-panel.vjs-volume-panel-horizontal:hover {
          width: 8em;
        }

        .video-js .vjs-progress-control {
          position: absolute;
          top: -2em;
          left: 0;
          right: 0;
          width: 100%;
          height: 0.5em;
          background: rgba(255, 255, 255, 0.2);
        }

        .video-js .vjs-progress-control:hover {
          height: 1em;
          top: -2.5em;
        }

        .video-js .vjs-progress-control .vjs-progress-holder {
          margin: 0;
        }

        .video-js .vjs-time-control {
          padding-left: 0.5em;
          padding-right: 0.5em;
          min-width: 2em;
        }

        .video-js .vjs-remaining-time {
          display: none;
        }

        .video-js .vjs-custom-control-spacer {
          display: flex;
          flex: 1;
        }

        .video-js .vjs-playback-rate {
          width: 4em;
        }

        .video-js .vjs-playback-rate .vjs-playback-rate-value {
          font-size: 1.2em;
          line-height: 2.4;
        }

        .video-js .vjs-playback-rate .vjs-menu {
          width: 80px;
          left: -28px;
        }

        .video-js .vjs-menu-button-popup .vjs-menu {
          bottom: 3em;
        }

        .video-js .vjs-menu li {
          font-size: 1.1em;
          padding: 0.4em 0;
        }

        .video-js .vjs-picture-in-picture-control {
          margin-right: 0.5em;
        }

        .video-js.vjs-fluid {
          padding-top: 56.25%;
        }
      `;
      document.head.appendChild(style);

      const player = videojs(videoElement, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        fluid: true,
        responsive: true,
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
        html5: {
          vhs: {
            overrideNative: true,
            fastQualityChange: true,
            enableLowInitialPlaylist: true,
            handleManifestRedirects: true,
            handlePartialData: true
          },
          nativeVideoTracks: false,
          nativeAudioTracks: false,
          nativeTextTracks: false
        },
        controlBar: {
          children: [
            {
              name: 'playToggle'
            },
            {
              name: 'volumePanel',
              inline: true,
              volumeControl: {
                vertical: true,
                volumeBar: {
                  vertical: true
                }
              }
            },
            {
              name: 'currentTimeDisplay'
            },
            {
              name: 'timeDivider'
            },
            {
              name: 'durationDisplay'
            },
            {
              name: 'progressControl'
            },
            {
              name: 'customControlSpacer',
              className: 'vjs-custom-control-spacer'
            },
            {
              name: 'pictureInPictureToggle',
            },
            {
              name: 'playbackRateMenuButton',
              playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2]
            },
            {
              name: 'fullscreenToggle'
            }
          ]
        }
      });

      playerRef.current = player;
      player.lastPlayTime = Date.now();

      // Initialize token refresh
      getNewToken()
        .then(token => {
          tokenRef.current = token;
          startTokenRefresh();
        })
        .catch(console.error);

      player.on('playing', () => {
        if (currentChannelRef.current?.id === channel?.id) {
          errorCountRef.current = 0;
          player.lastPlayTime = Date.now();
          isHandlingErrorRef.current = false;
        }
      });

      player.on('error', function(e) {
        const error = player.error();
        if (error.code === 2 || error.code === 4) {
          handleStreamFailure(player, error);
        }
      });

      player.on('waiting', function() {
        if (currentChannelRef.current?.id === channel?.id) {
          if (stallTimeoutRef.current) {
            clearTimeout(stallTimeoutRef.current);
          }
          
          stallTimeoutRef.current = setTimeout(() => {
            if (player.readyState() < 3 && !isHandlingErrorRef.current) {
              handleStreamFailure(player);
            }
          }, 10000);
        }
      });

      player.on('playing', () => {
        if (stallTimeoutRef.current) {
          clearTimeout(stallTimeoutRef.current);
          stallTimeoutRef.current = null;
        }
      });

      player.on('volumechange', () => {
        if (fadeIntervalRef.current) {
          clearInterval(fadeIntervalRef.current);
          fadeIntervalRef.current = null;
        }
      });
    }

    const loadStream = async () => {
      const player = playerRef.current;
      
      if (player && channel?.id) {
        try {
          currentChannelRef.current = channel;
          
          setIsUsingBackup(false);
          errorCountRef.current = 0;
          isHandlingErrorRef.current = false;
          
          [
            reconnectAttemptRef,
            healthCheckIntervalRef,
            primaryRetryTimeoutRef,
            stallTimeoutRef
          ].forEach(ref => {
            if (ref.current) {
              clearTimeout(ref.current);
              ref.current = null;
            }
          });

          console.log('Loading stream for channel:', channel.id);
            
          try {
            await switchToPrimaryStream(player, 0);
            fadeInVolume(player, 0.7, 0.9, 3000, 30);
          } catch (primaryError) {
            console.error('Primary stream failed:', primaryError);
              
            if (channel?.backup_stream_url && currentChannelRef.current?.id === channel?.id) {
              try {
                console.log('Switching to backup stream immediately');
                await switchToBackupStream(player, 0);
                fadeInVolume(player, 0.7, 0.9, 3000, 30);
                startPrimaryStreamCheck(player);
              } catch (backupError) {
                console.error('Backup stream failed:', backupError);
                if (currentChannelRef.current?.id === channel?.id) {
                  displayError(player, 'Không thể phát nội dung. Vui lòng thử lại sau.');
                }
              }
            } else {
              throw primaryError;
            }
          }
        } catch (error) {
          console.error('All streams failed:', error);
          if (currentChannelRef.current?.id === channel?.id) {
            displayError(player, 'Không thể phát nội dung. Vui lòng thử lại sau.');
          }
        }
      }
    };

    loadStream();

    return () => {
      [
        fadeIntervalRef,
        reconnectAttemptRef,
        healthCheckIntervalRef,
        primaryRetryTimeoutRef,
        stallTimeoutRef,
        tokenRefreshIntervalRef
      ].forEach(ref => {
        if (ref.current) {
          clearTimeout(ref.current);
          ref.current = null;
        }
      });
    };
  }, [channel]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      const player = playerRef.current;
      if (!player) return;

      switch(event.key) {
        case 'm':
        case 'M':
          player.muted(!player.muted());
          break;
        case ' ':
          if (player.paused()) {
            player.play();
          } else {
            player.pause();
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          player.volume(Math.min(player.volume() + 0.1, 1));
          event.preventDefault();
          break;
        case 'ArrowDown':
          player.volume(Math.max(player.volume() - 0.1, 0));
          event.preventDefault();
          break;
        case 'f':
        case 'F':
          if (player.isFullscreen()) {
            player.exitFullscreen();
          } else {
            player.requestFullscreen();
          }
          break;
        case 'p':
        case 'P':
          if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
          } else {
            player.requestPictureInPicture();
          }
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div className="aspect-video bg-black rounded-lg relative">
      <video
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        playsInline
      />
      {isUsingBackup && (
        <div className="absolute top-2 right-2 px-2 py-1 bg-yellow-500 text-white text-xs rounded">
          Đang sử dụng nguồn dự phòng
        </div>
      )}
      <div className="absolute bottom-2 left-2 text-xs text-white opacity-70 flex items-center space-x-2">
        <span>{channel?.name || 'Loading...'}</span>
        {document.pictureInPictureEnabled && (
          <button 
            className="text-xs px-1.5 py-0.5 bg-gray-800/50 rounded hover:bg-gray-700/50 transition-colors"
            onClick={() => {
              const player = playerRef.current;
              if (document.pictureInPictureElement) {
                document.exitPictureInPicture();
              } else if (player) {
                player.requestPictureInPicture();
              }
            }}
          >
            PiP
          </button>
        )}
        <div className="text-xs px-1.5 py-0.5 bg-gray-800/50 rounded">
          {playerRef.current?.playbackRate() || 1}x
        </div>
      </div>
      
      {/* Add volume indicator when changing volume */}
      <div 
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                    bg-black/80 text-white px-4 py-2 rounded-lg text-lg font-semibold
                    transition-opacity ${fadeIntervalRef.current ? 'opacity-100' : 'opacity-0'}`}
      >
        Volume: {Math.round((playerRef.current?.volume() || 0) * 100)}%
      </div>
    </div>
  );
};

export default VideoPlayer;