// src/utils/timeUtils.js

export const JAPANESE_CHANNELS = [
  '関東地上デジタル',
  '関西地上デジタル',
  'BS1',
  'BS2',
  'CS1',
  'CS3',
  'CS4',
  'CS5'
];

// Check if channel belongs to Japanese channels
export const isJapaneseChannel = (category) => {
  return JAPANESE_CHANNELS.includes(category);
};

// Adjust display time for Japanese channels (GMT+9 to GMT+7)
export const adjustTimeForJapaneseChannel = (time, category) => {
  if (!time || !isJapaneseChannel(category)) return time;

  const [hours, minutes] = time.split(':').map(Number);
  let adjustedHours = hours - 2; // Convert from GMT+9 to GMT+7

  // Handle day rollover
  if (adjustedHours < 0) {
    adjustedHours += 24;
  }

  return `${String(adjustedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

// Get current program considering time zone differences
export const getCurrentProgramForChannel = (schedule, category) => {
  if (!schedule.length) return null;

  const now = new Date();
  let currentHour = now.getHours();
  const currentMinutes = now.getMinutes();
  let currentTime = currentHour * 60 + currentMinutes;

  // Adjust current time for Japanese channels
  if (isJapaneseChannel(category)) {
    currentHour += 2;
    if (currentHour >= 24) {
      currentHour -= 24;
    }
    currentTime = currentHour * 60 + currentMinutes;
  }

  return schedule.find((item, index) => {
    const [hours, minutes] = item.time.split(':').map(Number);
    const programTime = hours * 60 + minutes;
    
    let nextProgramTime = 24 * 60;
    if (index < schedule.length - 1) {
      const [nextHours, nextMinutes] = schedule[index + 1].time.split(':').map(Number);
      nextProgramTime = nextHours * 60 + nextMinutes;
    }
    
    return currentTime >= programTime && currentTime < nextProgramTime;
  });
};

// Format time for display
export const formatTime = (time, category) => {
  if (!time) return '';
  return isJapaneseChannel(category) ? `${time} (GMT+9)` : time;
};