// src/components/Layout.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { authService } from '../services/api';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load user data
  useEffect(() => {
    try {
      const userString = localStorage.getItem('user');
      const token = localStorage.getItem('token');
      
      if (!userString || !token) {
        throw new Error('No user data or token');
      }

      const parsedUser = JSON.parse(userString);
      setUserData(parsedUser);
    } catch (error) {
      console.error('Error loading user data:', error);
      handleLogout();
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Validate session periodically
  useEffect(() => {
    let intervalId;
    let isValidating = false;

    const validateSession = async () => {
      if (isValidating) return;
      
      try {
        isValidating = true;
        const token = localStorage.getItem('token');
        
        if (!token) {
          throw new Error('No token found');
        }

        const isValid = await authService.validateToken();
        if (!isValid) {
          throw new Error('Invalid session');
        }
      } catch (error) {
        console.error('Session validation error:', error);
        if (!error.message.includes('Network') && !error.message.includes('Failed to fetch')) {
          handleLogout();
        }
      } finally {
        isValidating = false;
      }
    };

    // Initial delay before starting validation
    const timeoutId = setTimeout(() => {
      validateSession();
      // Check every minute after initial validation
      intervalId = setInterval(validateSession, 60 * 1000);
    }, 10000); // 10 second initial delay

    return () => {
      clearTimeout(timeoutId);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const handleLogout = async () => {
    if (isLoggingOut) return;
    
    setIsLoggingOut(true);
    try {
      await authService.logout();
    } catch (error) {
      console.error('Error during logout:', error);
      // Force logout on error
      localStorage.clear();
      window.location.href = '/login';
    } finally {
      setIsLoggingOut(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!userData) {
    navigate('/login', { replace: true });
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex items-center justify-between h-16">
            {/* Logo and Navigation */}
            <div className="flex items-center space-x-12">
              {/* Logo */}
              <h1 className="text-2xl font-bold text-blue-600">IPTV</h1>
              
              {/* Navigation */}
              <nav className="hidden md:flex space-x-1">
                <button
                  onClick={() => navigate('/')}
                  className={`px-4 py-2 rounded-md transition-all duration-200
                    ${location.pathname === '/' 
                      ? 'bg-blue-50 text-blue-600 font-medium shadow-sm' 
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}`}
                >
                  Truyền hình
                </button>
                <button
                  onClick={() => navigate('/movies')}
                  className={`px-4 py-2 rounded-md transition-all duration-200
                    ${location.pathname === '/movies' 
                      ? 'bg-blue-50 text-blue-600 font-medium shadow-sm' 
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}`}
                >
                  Phim
                </button>
                <button
                  onClick={() => navigate('/radio')}
                  className={`px-4 py-2 rounded-md transition-all duration-200
                    ${location.pathname === '/radio' 
                      ? 'bg-blue-50 text-blue-600 font-medium shadow-sm' 
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}`}
                >
                  Radio
                </button>
              </nav>
            </div>

            {/* User Info and Logout */}
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-4">
                <span className="text-gray-700">
                  Xin chào, {userData?.fullname || userData?.username}
                </span>
                {userData?.package_name && (
                  <span className="px-3 py-1 text-sm bg-gray-100 text-gray-600 rounded-full">
                    {userData.package_name}
                  </span>
                )}
              </div>
              <button
                onClick={handleLogout}
                disabled={isLoggingOut}
                className={`px-4 py-2 text-red-600 hover:bg-red-50 rounded-md transition-colors duration-200 
                  ${isLoggingOut ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isLoggingOut ? 'Đang đăng xuất...' : 'Đăng xuất'}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <Outlet />
      </main>

      {/* Footer */}
      <footer className="bg-white shadow-md mt-8">
        <div className="max-w-7xl mx-auto py-4 px-4 text-center text-gray-600">
          © 2024 NEWTV - IPTV System
        </div>
      </footer>
    </div>
  );
};

export default Layout;