// src/components/ProgramSchedule.jsx
import React from 'react';
import { COLORS } from '../constants/theme';

const ProgramSchedule = ({ 
  schedule, 
  currentProgram, 
  currentProgramRef, 
  scheduleContainerRef,
  selectedDate 
}) => {
  const isToday = selectedDate === new Date().toISOString().slice(0, 10);

  return (
    <div 
      ref={scheduleContainerRef}
      className="overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-gray-300 
                scrollbar-track-gray-100 hover:scrollbar-thumb-gray-400"
      style={{ height: '500px' }}
    >
      {schedule.length > 0 ? (
        schedule.map((item, index) => {
          const isCurrentProgram = isToday && currentProgram?.time === item.time;
          
          return (
            <div 
              key={index} 
              ref={isCurrentProgram ? currentProgramRef : null}
              className={`
                p-4 transition-all duration-300 ease-in-out
                border-b border-gray-100 last:border-b-0
                ${isCurrentProgram 
                  ? 'bg-primary-50/80 hover:bg-primary-50' 
                  : 'hover:bg-gray-50'}
              `}
            >
              <div className="flex items-start space-x-4">
                {/* Time Badge */}
                <div className={`
                  px-3 py-1.5 rounded-full text-sm font-medium
                  transition-colors duration-300
                  ${isCurrentProgram 
                    ? 'bg-primary-100 text-primary-700' 
                    : 'bg-gray-100 text-gray-600'}
                `}>
                  {item.time}
                </div>

                {/* Program Content */}
                <div className="flex-1 min-w-0">
                  <p className={`
                    text-base leading-relaxed
                    ${isCurrentProgram ? 'font-semibold text-gray-900' : 'text-gray-700'}
                  `}>
                    {item.program}
                    {isCurrentProgram && (
                      <span className="ml-2 inline-flex items-center text-sm font-bold text-primary-600">
                        <span className="mr-1.5 h-2 w-2 rounded-full bg-primary-500 animate-pulse" />
                        Đang phát
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex flex-col items-center justify-center h-full p-8">
          <div className="w-16 h-16 mb-4 text-gray-400">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <p className="text-gray-500 text-center">
            Chưa có lịch phát sóng cho ngày này
          </p>
        </div>
      )}
    </div>
  );
};

export default ProgramSchedule;
