// src/pages/Home.jsx
import React, { useState, useEffect, useRef } from 'react';
import { channelService } from '../services/api';
import VideoPlayer from '../components/VideoPlayer';
import ChannelCard from '../components/ChannelCard';
import ProgramSchedule from '../components/ProgramSchedule';
import DateSelector from '../components/DateSelector';
import CurrentProgramInfo from '../components/CurrentProgramInfo';
import { 
  adjustTimeForJapaneseChannel, 
  getCurrentProgramForChannel,
  isJapaneseChannel 
} from '../utils/timeUtils';

const Home = () => {
  // State declarations
  const [channels, setChannels] = useState([]);
  const [channelCategories, setChannelCategories] = useState({});
  const [currentChannel, setCurrentChannel] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [scheduleByDate, setScheduleByDate] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentProgram, setCurrentProgram] = useState(null);

  // Refs
  const currentProgramRef = useRef(null);
  const scheduleContainerRef = useRef(null);
  // Effects
  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (currentChannel?.epg_url) {
      loadEPG(currentChannel.epg_url);
      const intervalId = setInterval(() => loadEPG(currentChannel.epg_url), 300000);
      return () => clearInterval(intervalId);
    }
  }, [currentChannel]);

  useEffect(() => {
    if (schedule.length > 0 && selectedDate === new Date().toISOString().slice(0, 10)) {
      updateCurrentProgram();
      const intervalId = setInterval(updateCurrentProgram, 60000);
      return () => clearInterval(intervalId);
    }
  }, [schedule, selectedDate]);

  useEffect(() => {
    if (currentProgramRef.current && scheduleContainerRef.current) {
      const container = scheduleContainerRef.current;
      const element = currentProgramRef.current;
      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      
      const scrollTo = element.offsetTop - (container.clientHeight / 2) + (elementRect.height / 2);
      container.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }
  }, [currentProgram, schedule]);

  // Initialize data
  const initializeData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.package_id) {
        throw new Error('Không tìm thấy thông tin gói dịch vụ');
      }
      await loadChannels(user.package_id);
    } catch (error) {
      console.error('Initialization error:', error);
      setError(error.message);
    }
  };
  // Load channels
  const loadChannels = async (packageId) => {
    try {
      setLoading(true);
      setError('');

      const packageResponse = await fetch(`/api/packages/${packageId}`);
      const packageData = await packageResponse.json();
      
      if (packageData.status !== 'success' || !packageData.data) {
        throw new Error('Không thể tải thông tin gói dịch vụ');
      }

      const allowedChannelIds = packageData.data.channels || [];
      const channelsResponse = await channelService.getChannels();

      const filteredChannels = {};
      Object.entries(channelsResponse).forEach(([category, channels]) => {
        const allowedChannels = channels.filter(channel => 
          allowedChannelIds.includes(channel.id)
        );

        if (allowedChannels.length > 0) {
          filteredChannels[category] = allowedChannels;
        }
      });

      setChannelCategories(filteredChannels);
      
      const firstCategory = Object.values(filteredChannels)[0];
      if (firstCategory?.length > 0) {
        setCurrentChannel(firstCategory[0]);
      }

    } catch (err) {
      console.error('Channel loading error:', err);
      setError(err.message || 'Không thể tải danh sách kênh');
    } finally {
      setLoading(false);
    }
  };

  // Load EPG data
  const loadEPG = async (epgUrl) => {
    try {
      const rawXmlText = await channelService.getEPG(epgUrl);
      if (!rawXmlText) return;

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(rawXmlText, 'text/xml');
      
      const programData = {};
      const timeTable = xmlDoc.querySelector('timeTable');

      if (timeTable) {
        Array.from(timeTable.getElementsByTagName('item')).forEach(item => {
          const date = item.getAttribute('name') || item.getAttribute('date');
          if (!date) return;

          let formattedDate = date.length === 8
            ? `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
            : new Date(date).toISOString().slice(0, 10);

          const programs = Array.from(item.getElementsByTagName('prgItem'))
            .map(prog => {
              const timeElement = prog.getElementsByTagName('time')[0];
              const nameElement = prog.getElementsByTagName('name')[0];

              let programName = '';
              if (nameElement) {
                programName = nameElement.textContent || nameElement.innerHTML || '';
                programName = programName
                  .replace(/<!\[CDATA\[|\]\]>/g, '')
                  .replace(/&lt;!\[CDATA\[|\]\]&gt;/g, '')
                  .replace(/^\s+|\s+$/g, '')
                  .replace(/\s+/g, ' ');
              }

              const timeText = timeElement ? timeElement.textContent || timeElement.innerHTML : '';
              
              // Adjust time for Japanese channels
              const adjustedTime = adjustTimeForJapaneseChannel(
                timeText.trim(), 
                currentChannel?.category
              );

              return {
                time: adjustedTime,
                program: programName
              };
            })
            .filter(prog => prog.time && prog.program);

          if (programs.length > 0) {
            programData[formattedDate] = programs;
          }
        });

        setScheduleByDate(programData);
        
        const dates = Object.keys(programData).sort();
        if (dates.length > 0) {
          const defaultDate = programData[selectedDate] ? selectedDate : dates[0];
          setSelectedDate(defaultDate);
          setSchedule(programData[defaultDate] || []);
        }
      }
    } catch (error) {
      console.error('EPG loading error:', error);
    }
  };
  // Update current program
  const updateCurrentProgram = () => {
    if (!schedule.length || !currentChannel) return;
    const currentProg = getCurrentProgramForChannel(schedule, currentChannel.category);
    setCurrentProgram(currentProg);
  };

  // Event handlers
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSchedule(scheduleByDate[date] || []);
  };

  const handleChannelSelect = (channel) => {
    setCurrentChannel(channel);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Loading state
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Đang tải dữ liệu...</p>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="text-center max-w-md mx-auto px-4">
          <div className="w-20 h-20 mx-auto mb-6 text-error-500">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">Đã có lỗi xảy ra</h3>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={initializeData}
            className="px-6 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                     transition-colors duration-200"
          >
            Thử lại
          </button>
        </div>
      </div>
    );
  }
  // Main render
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
          {/* Video and Info Section */}
          <div className="lg:col-span-8 space-y-6">
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
              {currentChannel ? (
                <>
                  <VideoPlayer channel={currentChannel} />
                  <CurrentProgramInfo 
                    channel={currentChannel}
                    currentProgram={currentProgram}
                  />
                </>
              ) : (
                <div className="aspect-video bg-gradient-to-r from-gray-700 to-gray-900 
                              flex items-center justify-center text-white text-lg font-medium">
                  Chưa có kênh nào được chọn
                </div>
              )}
            </div>
          </div>

          {/* Schedule Section */}
          <div className="lg:col-span-4">
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
              <div className="p-6 border-b border-gray-100">
                <h3 className="font-bold text-xl text-gray-800">
                  Lịch phát sóng {currentChannel && `- ${currentChannel.name}`}
                </h3>
              </div>

              <DateSelector 
                dates={Object.keys(scheduleByDate).sort()}
                selectedDate={selectedDate}
                onDateSelect={handleDateSelect}
              />

              <ProgramSchedule 
                schedule={schedule}
                currentProgram={currentProgram}
                currentProgramRef={currentProgramRef}
                scheduleContainerRef={scheduleContainerRef}
                selectedDate={selectedDate}
                channelCategory={currentChannel?.category}
              />
            </div>
          </div>
        </div>

        {/* Channel List Section */}
        <div className="mt-8 bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-100">
            <h3 className="text-xl font-bold text-gray-800">Danh sách kênh</h3>
          </div>
          
          <div className="p-6">
            {Object.entries(channelCategories).map(([category, channelList]) => (
              <div key={category} className="mt-8 first:mt-0" id={`category-${category}`}>
                <h4 className="text-lg font-semibold text-gray-700 mb-4">{category}</h4>
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4">
                  {channelList.map((channel) => (
                    <ChannelCard
                      key={channel.id}
                      channel={channel}
                      isActive={currentChannel?.id === channel.id}
                      onClick={handleChannelSelect}
                    />
                  ))}
                </div>
              </div>
            ))}

            {Object.keys(channelCategories).length === 0 && (
              <div className="text-center py-12">
                <div className="mx-auto w-24 h-24 rounded-full bg-gray-100 
                              flex items-center justify-center mb-4">
                  <svg className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" 
                       stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-gray-900">Không có kênh nào</h3>
                <p className="mt-2 text-gray-500">
                  Không có kênh nào trong gói dịch vụ của bạn.
                  <br />
                  Vui lòng liên hệ admin để được hỗ trợ.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Categories Quick Navigation */}
        <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-40">
          <div className="bg-white/90 backdrop-blur-sm rounded-full shadow-lg border border-gray-200">
            <div className="px-4 py-2 flex space-x-4 overflow-x-auto no-scrollbar">
              {Object.keys(channelCategories).map((category) => (
                <button
                  key={category}
                  onClick={() => {
                    const element = document.getElementById(`category-${category}`);
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-primary-600 
                           hover:bg-primary-50 rounded-full transition-colors whitespace-nowrap"
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Float Action Button - Back to Top */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="fixed bottom-6 right-6 p-3 bg-primary-500 text-white rounded-full shadow-lg 
                   hover:bg-primary-600 transition-colors duration-200 group z-40"
        >
          <svg className="w-6 h-6 transform group-hover:-translate-y-1 transition-transform duration-200" 
               fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
        </button>

        {/* Error Toast */}
        {error && (
          <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50">
            <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 
                         px-6 py-3 rounded-lg shadow-lg flex items-center space-x-3
                         animate-fade-in">
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span className="font-medium">{error}</span>
              <button
                onClick={() => setError('')}
                className="ml-4 text-yellow-700 hover:text-yellow-900 focus:outline-none"
              >
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        )}

        {/* Loading Overlay */}
        {loading && (
          <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center 
                       justify-center z-50">
            <div className="bg-white rounded-2xl p-8 max-w-sm mx-4 w-full animate-fade-in">
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 border-4 border-primary-500 border-t-transparent 
                             rounded-full animate-spin"></div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Đang tải</h3>
                  <p className="text-sm text-gray-500">Vui lòng đợi trong giây lát...</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
			